import React from 'react'
import Layout from '../components/Layout'
import PaddedLayout from '../components/PaddedLayout'
import '../styles.scss'
export default function About() {
    return (
        <Layout>
                <div className="gallery-header">
                    <h4>About <span className="blue">Tomcat</span></h4>
                </div>
            <PaddedLayout>
                    <p>
                    We've built our reputation by providing the toughest, best-engineered, highest-value machines our industry can produce. We make sure that in every market in which our more than 350 distributors in 36 countries and service centers do business, they maintain a "factory" trained service department with a vested interest in our customers long-term satisfaction.
                    </p>
                    <p>
                    Tomcat offers a line of cleaning equipment that includes sweepers and scrubbers, in both walk-behind and ride-on models. All our models are practical, rugged machines that are sensibly priced. Your local dealer will be happy to assist you with rentals, soap, and floor sealer information. They will gladly provide service on all brands of cleaning equipment.   
                    </p>
            </PaddedLayout>
        </Layout>
    )
}